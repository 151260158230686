import React from "react";
class Captcha extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            code:''
        };
    }

    componentDidMount() {
        const canvas = this.refs.canvas;
        const ctx = canvas.getContext("2d");
        ctx.font = "25px Georgia";
        ctx.strokeText(this.props.code, 0, 30);
    }
    render() {
        return(
                <canvas ref="canvas" width={150} height={50} />
        )
    }
}
export default Captcha