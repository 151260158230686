import React from "react"
import Layout from "../components/layout"
import Helmet from "react-helmet"
import Captcha from "../components/captcha"
import "animate.css/animate.min.css"


export default class checklistpage extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      url: "",
      name: "",
      email: "",
      phone: "",
      website: "",
      message: "",
      response: "",
      displayResult: false,
      displayClass: "",
      _currentURL: "",
      code: this.generateCaptchaCode(),
      captcha: "",
      submit_text: "Submit",
      is_processing: false,
      host: ''
    }
    this.changeCaptcha = this.changeCaptcha.bind(this)
  }

  generateCaptchaCode() {
    let charsArray =
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ@!#$%^&*"
    let lengthOtp = 6
    let captcha = []
    for (let i = 0; i < lengthOtp; i++) {
      //below code will not allow Repetition of Characters
      let index = Math.floor(Math.random() * charsArray.length + 1) //get the next character from the array
      if (captcha.indexOf(charsArray[index]) === -1)
        captcha.push(charsArray[index])
      else i--
    }

    return captcha.join("")
  }

  componentDidMount() {
    this.setState({
      url: document.location.href,
      host: document.location.host,
    })
  }
  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(email)
  }
  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name

    if (name === "website") {
      let urlregex = new RegExp("^(?:https?:\\/\\/)?(?!www | www\\.)[A-Za-z0-9_-]+\\.+[A-Za-z0-9.\\/%&=\\?_:;-]+$")

      // if(value.trim().length>0) {
      if (!urlregex.test(value.trim())) {
        target.setCustomValidity("Please enter a valid domain name. example.com")
      } else {
        target.setCustomValidity("")
      }
      // }else{
      //   target.setCustomValidity("")
      // }
    }

    if (name === "email") {
      if (!this.validateEmail(value.trim().toLowerCase())) {
        target.setCustomValidity("Please enter a valid email. email@domain.com")
      } else {
        target.setCustomValidity("")
      }
    }

    this.setState({
      [name]: value,
    })
  }

  changeCaptcha() {
    this.setState({
      code: this.generateCaptchaCode(),
      key: Date.now(),
    })
  }

  handleSubmit = event => {
    event.preventDefault()
    //check captcha
    if (this.state.captcha !== this.state.code) {
      this.setState({
        response: "Captcha not matched",
        displayResult: true,
        displayClass: "error-msg",
      })
      setTimeout(() => {
        this.setState({ displayResult: false })
      }, 3000)
      return
    }
    this.setState({
      submit_text: "Submitting...",
      is_processing: true,
    })

    let HUBSPOT_CONTACT_FORM
    if (this.state.host === "cb-redesign.netlify.com" || this.state.host === "cb-redesign.netlify.app") {
      //STAGING FORM
      HUBSPOT_CONTACT_FORM = "https://api.hsforms.com/submissions/v3/integration/submit/6881310/e521c367-a1c1-46c9-a40e-795b6dfd4b0c"
    } else if (this.state.host === "www.cueforgood.com" || this.state.host === "cueforgood.com") {
      //LIVE FORM
      HUBSPOT_CONTACT_FORM = "https://api.hsforms.com/submissions/v3/integration/submit/6881310/59486c20-2ace-4acb-9f16-5881bdb9e64a"
    } else {
      //STAGING FORM
      HUBSPOT_CONTACT_FORM = "https://api.hsforms.com/submissions/v3/integration/submit/6881310/e521c367-a1c1-46c9-a40e-795b6dfd4b0c"
    }
    let data = {
      "submittedAt": new Date().getTime(),
      "fields": [
        {
          "name": "email",
          "value": this.state.email,
        },
        {
          "name": "firstname",
          "value": this.state.name,
        },
        {
          "name": "phone",
          "value": this.state.phone,
        },
        {
          "name": "website",
          "value": this.state.website,
        },
        {
          "name": "message",
          "value": this.state.message,
        },
      ],

      "legalConsentOptions": { // Include this object when GDPR options are enabled
        "consent": {
          "consentToProcess": true,
          "text": "I agree to allow Example Company to store and process my personal data.",
          "communications": [
            {
              "value": true,
              "subscriptionTypeId": 999,
              "text": "I agree to receive marketing communications from Example Company.",
            },
          ],
        },
      },
    }

    const final_data = JSON.stringify(data)

    let _message = ""
    let _this = this
    fetch(HUBSPOT_CONTACT_FORM, {
      method: "post",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: final_data,
    },
    ).then((response) => {
      if (response.status === 200) {
        return response.json()
      } else {
        response.json().then(function (response) {
          response.errors.forEach((data, index) => {
            console.log(data.message, index)
            //console.log(data, index);
            if (_message === "") {
              _message = data.message
            } else {
              _message += "</br>" + data.message
            }
          })
          _this.setState({
            response: _message,
            displayResult: true,
            displayClass: "error-msg",
            submit_text: "Submitted",
            is_processing: false,
          })
          setTimeout(() => {
            _this.setState({ displayResult: false, submit_text: "Submit" })
          }, 5000)
        })
        throw Error("Missing params")
      }
    }).then((response) => {
      _this.setState({
        response: response.inlineMessage,
        name: "",
        email: "",
        phone: "",
        website: "",
        message: "",
        captcha: "",
        displayResult: true,
        displayClass: "success-msg",
        submit_text: "Submitted",
        is_processing: false,
      })
      _this.changeCaptcha()
      setTimeout(() => {
        _this.setState({ displayResult: false, submit_text: "Submit" })
      }, 5000)
    }).catch((error) => {
      console.error(error)
      _this.setState({ submit_text: "Submit" })
    })
  }

  render() {
    return <Layout>
      <Helmet>
        <title>Download the Holy Grail of eCommerce Conversion Rate Optimization - a 91 Point Checklist & Infographic |
          e-Commerce Conversion Optimization Guide</title>
        <link rel="canonical"
          href={this.state.url} />
        <meta name="description"
          content="Download the Holy Grail of eCommerce Conversion Rate Optimization - a 91 Point Checklist & Infographic | e-Commerce Conversion Optimization Guide" />
          <meta name="viewport" content="initial-scale=1.0, width=device-width, maximum-scale=5.0, minimum-scale=1.0" />
        <link rel="shortcut icon" href="https://www.cueforgood.com/favicon.ico" />
        <meta property="og:site_name" content="CueForGood" />
        <meta property="fb:app_id" content="289086684439915" />
        <meta property="og:url" content={this.state.url} />
        <meta property="og:title"
          content="Download the Holy Grail of eCommerce Conversion Rate Optimization - a 91 Point Checklist & Infographic | e-Commerce Conversion Optimization Guide" />
        <meta property="og:description"
          content="Download the Holy Grail of eCommerce Conversion Rate Optimization - a 91 Point Checklist & Infographic | e-Commerce Conversion Optimization Guide" />
        <meta property="og:image" content="https://www.cueforgood.com/images/cb.jpg" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:description"
          content="Download the Holy Grail of eCommerce Conversion Rate Optimization - a 91 Point Checklist & Infographic | e-Commerce Conversion Optimization Guide" />
        <meta name="twitter:title"
          content="Download the Holy Grail of eCommerce Conversion Rate Optimization - a 91 Point Checklist & Infographic | e-Commerce Conversion Optimization Guide" />
        <meta name="twitter:site" content="@CueForGood" />
        <meta name="twitter:image" content="https://www.cueforgood.com/images/cb.jpg" />
      </Helmet>
      <article className="holly holly_grail">
        <section className="main seo">
          <div className=" wrap">
            <div className="get_free">
              <div className="head">
                <p>Get a free copy of</p>
                <h1>HOLY GRAIL OF ECOMMERCE CONVERSION OPTIMIZATION</h1>
                <h6>A 91-Point Checklist & Infographic By CueForGood</h6>
              </div>
              <div id="mc_embed_signup" className="mc_embed_signup1">
                <form
                  action="//cueblocks.us5.list-manage.com/subscribe/post?u=f1428e4bc08f78bd02dc4abda&amp;id=73e460210b"
                  method="post"
                  id="mc-embedded-subscribe-form"
                  name="mc-embedded-subscribe-form"
                  className="validate"
                  target="_blank"
                  noValidate
                >
                  <div className="mc-field-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      placeholder="Send me a copy"
                      name="EMAIL"
                      id="first-focus-element" 
                      className="email focus-visible"
                      aria-label="Send me a copy" 
                      required
                    />
                    <input type="hidden" name="LOC1" value="Holygrail" />
                    <div className="btn_go">
                      <input
                        type="submit"
                        value="Send"
                        name="subscribe"
                        id="mc-embedded-subscribe"
                        className="button main_cta focus-visible"
                        aria-label="Send"
                      />
                    </div>
                  </div>
                </form>
              </div>
              <p className="point-text">
                And subscribe to our mailing list. We'll treat your email address with the utmost respect and won't sell it or rent it.
              </p>
            </div>

            <div className="wrapb1_right contact-form__block">
              <div className="cont_frm">
                <h2>Get Conversion Rate Audit</h2>
                <p>
                  Want to maximize the Conversion Rate of your store? Get CueForGood's Conversion Optimization experts to evaluate your store.
                </p>
                <span className="indicate">(<em>*</em> indicates mandatory fields)</span>
                <div className="cont-frm1">
                  <form method="post" name="contact-form" id="contact-form" onSubmit={this.handleSubmit}>
                    <div className="form__row">
                      <label htmlFor="name">Name<em>*</em></label>
                      <input
                        type="text"
                        name="name"
                        required
                        id="name"
                        placeholder="Enter your name"
                        className="input1 focus-visible"
                        value={this.state.name}
                        onChange={this.handleInputChange}
                        aria-required="true"
                      />
                      <div className="border-line"></div>
                    </div>
                    <br />
                    <div className="form__row">
                      <label htmlFor="emailContact" >Email<em>*</em></label>
                      <input
                        type="text"
                        name="email"
                        id="emailContact "
                        placeholder="Enter your email"
                        title="hello@domain.com"
                        className="input1 focus-visible"
                        value={this.state.email}
                        onChange={this.handleInputChange}
                        aria-required="true"
                      />
                      <div className="border-line"></div>
                    </div>
                    <br />
                    <div className="form__row">
                      <label htmlFor="website">Website<em>*</em></label>
                      <input
                        type="text"
                        required
                        name="website"
                        id="website"
                        placeholder="Enter website here"
                        title="example.com"
                        className="input1 focus-visible"
                        value={this.state.website}
                        onChange={this.handleInputChange}
                        aria-required="true"
                      />
                      <div className="border-line"></div>
                    </div>
                    <br />
                    <div className="form__row">
                      <label htmlFor="phone">Phone<em>*</em></label>
                      <input
                        type="text"
                        name="phone"
                        id="phone"
                        placeholder="+1-123-456-789"
                        className="input1 focus-visible"
                        required
                        value={this.state.phone}
                        onChange={this.handleInputChange}
                        aria-required="true"
                      />
                      <div className="border-line"></div>
                    </div>
                    <br />
                    <label htmlFor="message">Message<em>*</em></label>
                    <textarea
                      name="message"
                      rows="4"
                      id="message"
                      cols="17"
                      required
                      placeholder="Message"
                      className="required focus-visible"
                      value={this.state.message}
                      onChange={this.handleInputChange}
                      aria-required="true"
                    ></textarea>
                    <br />
                    <div className="form__row captcha-row" key={this.state.key}>
                      <Captcha {...this.state} />
                      <em>*</em>
                      <button className="chnage-captcha focus-visible" tabIndex={0} onClick={this.changeCaptcha}>
                        Change
                      </button>
                      <label htmlFor="captcha" className="visually-hidden lp-captcha">Captcha</label>
                      <input
                        type="text"
                        required
                        name="captcha"
                        id="captcha"
                        placeholder="Captcha"
                        className="input1 focus-visible"
                        autoComplete="off"
                        value={this.state.captcha}
                        onChange={this.handleInputChange}
                        aria-required="true"
                      />
                    </div>
                    <br />
                    <br />
                    <input
                      type="submit"
                      name="submit"
                      id="submit-contact"
                      value={this.state.submit_text}
                      className="submit_con main_cta focus-visible"
                      disabled={this.state.is_processing}
                    />
                    {this.state.displayResult ? (
                      <div
                        className={this.state.displayClass}
                        style={{ textAlign: "center", marginTop: "10px" }}
                        dangerouslySetInnerHTML={{ __html: this.state.response }}
                      ></div>
                    ) : (
                      ""
                    )}
                  </form>
                </div>
              </div>
            </div>

            <div className="wrapb1">
              <div className="wrapb1_left">
                <h2>These are FREE 41 pages of an eBook -</h2>
                An Easy-to-implement 91-point checklist for E-commerce Conversion Rate Optimization -
                Many are calling it the hottest resource for E-commerce conversion optimization on the Internet.
                <h2>A Giant ready-to-print Infographic for your workplace -</h2>
                <p>Put it on your wall, and you will never miss a thing related to a store's conversion rate.</p>
                <h2>You will learn:</h2>
                <ul>
                  <li>How to know your customers and take guessing work out of conversion rate optimization</li>
                  <li>Things that you can show on your home page to improve your store's conversion rate</li>
                  <li>How to build navigation that your visitors will keep thanking you for</li>
                  <li>How to improve your product search to convert more visitors into customers</li>
                  <li>Things that your customers want to see on your product pages</li>
                  <li>About Checkout usability issues on your store that might be turning your customers off</li>
                  <li>How to break down user experience into actionable touch points</li>
                  <li>Information useful to your visitors to make your store stand out from its competitors</li>
                  <li>Things you must do today to increase your page load speed</li>
                  <li>Tips from UPS on how to handle shipping to make people order with more ease</li>
                  <li>How to use customer re-targeting to convert visitors who leave your store without taking action</li>
                </ul>
                And that's just the tip of the iceberg. This eBook will not only give you the much-needed 'big' push but also a roadmap towards making more profits from the same amount of traffic.
                <br />
                <br />
              </div>
              <div className="clr"></div>
            </div>

          </div>
        </section>
      </article>

    </Layout>
  }
}
